// import Vue from 'vue'
// import App from './App.vue'

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

/*引入vue框架*/
import Vue from "vue";
/*引入根组件*/
import App from "./App.vue";
/*引入路由设置*/
import router from "./router";
/*关闭生产模式下给出的提示*/
Vue.config.productionTip = false;

// 引入前端插件
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

//引入组件
import Layout from "./components/Layout.vue";

Vue.use(ElementUI);

/*定义实例*/
new Vue({
  el: "#app",
  router,
  components: { Layout },
  template: "<Layout/>",
  render: (h) => h(Layout),
});
