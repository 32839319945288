<template>
  <div>
    <div>公司简介</div>
    <div>
      每日买菜创立于2022年5月，致力于通过产地直采、前置仓配货和最快29分钟配送到家的服务模式，通过技术驱动产业链升级，
      为用户提供品质确定、时间确定、品类确定的生鲜消费体验。
      服务范围覆盖上海、北京、深圳、杭州、苏州等城市，是用户信赖的民生互联网企业。
    </div>
    
    <div id="container" style="height: 400px; border: 1px solid red"></div>
  </div>
</template>

<script>
//在项目终端安装echarts：npm install echarts --save
import * as echarts from "echarts";

export default {
  name: "AboutMe",
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.drawBar();
    }, 1500);
  },
  methods: {
    drawBar() {
      var dom = document.getElementById("container");
      var myChart = echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      var app = {};

      var option;

      option = {
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: "line",
          },
        ],
      };

      if (option && typeof option === "object") {
        myChart.setOption(option);
      }

      window.addEventListener("resize", myChart.resize);
    },
  },
};
</script>

<style>
</style>